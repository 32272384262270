export default function enumToFriendly(enumValue: string) {
  return enumValue.toLowerCase().replace("_", "-");
}

export function friendlyToEnum(friendlyValue?: string) {
  if (!friendlyValue) {
    return undefined;
  }
  return friendlyValue.toUpperCase().replace("-", "_");
}
